@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Playfair+Display:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap"); */
@import url("../node_modules/react-grid-layout/css/styles.css");
@import url("../node_modules/react-resizable/css/styles.css");

html,
body,
#root {
	width: 100%;
	min-height: 100vh;
	display: flex;
	/* height: max-content; */
}

html {
	font-size: 12px;
}

body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	display: flex;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */

a {
	text-decoration: underline;
	cursor: pointer;
	font-size: 14px;
}

.col-w-50 {
	display: flex;
	flex-direction: column;
	width: 45%;
}

.col-w-100 {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.finance-email-enter,
.finance-email-exit {
	transition: all 350ms ease-out;
	-o-transition: all 350ms ease-out;
	-moz-transition: all 350ms ease-out;
	-webkit-transition: all 350ms ease-out;
	overflow: hidden;
}
.finance-email-enter,
.finance-email-exit.finance-email-exit-active {
	opacity: 0;
	transform: scaleY(0.5);
	/* transform-origin: top center; */
}
.finance-email-exit,
.finance-email-enter.finance-email-enter-active {
	opacity: 1;
	transform: scaleY(1);
	/* transform-origin: top center; */
}

.expand-down {
	animation: scaleAnimationIn 0.3s;
	animation-fill-mode: both;
	animation-timing-function: ease;
	transform-origin: top center;
}

@keyframes scaleAnimationIn {
	0% {
		opacity: 0;
		transform: scaleY(0);
		max-height: 0;
	}
	100% {
		opacity: 1;
		transform: scaleY(1);
		max-height: 300px;
	}
}

.scale-down {
	animation: scaleAnimationOut 0.3s;
	animation-fill-mode: both;
	animation-timing-function: ease;
	transform-origin: top center;
}

@keyframes scaleAnimationOut {
	0% {
		opacity: 1;
		transform: scaleY(1);
		max-height: 300px;
	}
	100% {
		opacity: 0;
		transform: scaleY(0);
		max-height: 0;
	}
}
